button.bpi-button {
    @apply flex justify-center items-center border border-transparent rounded-md font-medium;

    &.contained {
        @apply text-white shadow-sm;

        &.primary {
            @apply bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.secondary {
            @apply bg-success hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success;
        }

        &:disabled {
            @apply bg-gray-300 hover:bg-gray-300 hover:cursor-default;
        }
    }

    &.text {
        &.primary {
            @apply bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.secondary {
            @apply bg-transparent text-success hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success;
        }

        &:disabled {
            @apply text-gray-300 hover:text-gray-300 hover:cursor-default;
        }
    }

    &.outlined {
        @apply shadow-sm;

        &.primary {
            @apply border border-primary hover:border-primary-dark bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.secondary {
            @apply border border-success hover:border-primary bg-transparent text-success hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success;
        }

        &:disabled {
            @apply border-gray-300 hover:border-gray-300 text-gray-300 hover:text-gray-300  hover:cursor-default;
        }
    }

    &.small {
        @apply py-1 px-2 text-xs;
    }

    &.medium {
        @apply py-2 px-4 text-sm;
    }

    &.large {
        @apply py-4 px-6 text-base;
    }

    &.full-width {
        @apply w-full;
    }
}
