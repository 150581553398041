.search-component-container {
    @apply w-full h-full flex flex-col overflow-hidden;

    .input-container {
        @apply p-2;

        .input-container-relative {
            @apply relative;

            .loader-container {
                @apply absolute top-0 bottom-0 right-2 flex items-center;
            }
        }
    }

    .results-found-container {
        @apply flex items-center p-2;

        .results-found {
            @apply text-black text-opacity-50 mr-2;
        }

        .controls-previous {
            @apply ml-auto mr-2;
        }
    }

    .highlighted {
        background-color: rgb(255, 255, 0);
    }

    .matches-container {
        @apply flex-1 border-t border-black border-opacity-20 overflow-auto pt-2 pb-2 pl-4 pr-4;

        .match-container {
            @apply mt-6 mb-6 ml-0 mr-0;

            .match-info {
                @apply flex justify-between mb-2;

                .match-page {
                    @apply text-black text-opacity-50 text-xs text-right;
                }
            }

            .match-preview {
                @apply border border-black border-opacity-20 rounded-sm cursor-pointer break-words p-2;

                &.isCurrent {
                    @apply bg-black bg-opacity-10;
                }
            }
        }
    }

    &.dark {
        .results-found-container {
            .results-found {
                @apply text-white;
            }
        }

        .highlighted {
            @apply text-black;
        }

        .matches-container {
            @apply border-t border-white;

            .match-container {
                .match-info {
                    .match-page {
                        @apply text-white;
                    }
                }

                .match-preview {
                    @apply border-white;
                }
            }
        }
    }
}
