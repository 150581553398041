.reset-password-container {
    @apply flex justify-center min-h-screen bg-primary antialiased;

    .main-container {
        @apply container sm:mt-40 mt-24 my-auto max-w-md border-2 border-gray-200 p-3 bg-white;

        .header {
            @apply text-center p-6;

            .logo {
                @apply h-14 w-auto m-auto mb-3;
            }

            .title {
                @apply text-3xl font-semibold text-gray-700;
            }
        }
    }
}
