.redirect-container {
    .redirect-text {
        @apply text-xl py-5;

        span {
            @apply font-bold;
        }
    }

    .redirect-url {
        @apply text-blue-900;
    }
}
